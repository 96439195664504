export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/": [2],
		"/advertiser-sorting": [3],
		"/audit-logs": [4],
		"/countries": [5],
		"/data-access": [6],
		"/data-upload": [7],
		"/entities-groups": [12],
		"/entities-groups/new": [14],
		"/entities-groups/[id]": [13],
		"/entities": [8],
		"/entities/guidance": [11],
		"/entities/[country_id]": [9],
		"/entities/[entity_id]/countries/[country_id]": [10],
		"/fact-checking": [15],
		"/google-advertiser-sorting": [16],
		"/login": [17],
		"/post-impressions": [18],
		"/profile": [19],
		"/reports": [20],
		"/reports/new": [22],
		"/reports/[id]": [21],
		"/system-health": [23],
		"/tasks": [24],
		"/users": [25],
		"/users/new": [27],
		"/users/[id]": [26]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';